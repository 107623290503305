import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import Img from "@/lib/gatsby-image"
import Video from "@/components/kit/video"

import * as style from "./gridItem.modules.scss"

export default function GridItem({
  description,
  background,
  border = true,
  fluid,
  file,
  className,
  sizes,
  ...rest
}) {
  const rootRef = useRef(null)
  const imageRef = useRef()

  return (
    <div
      ref={rootRef}
      className={cn(style.root, className, { "h-full": !description })}
      {...rest}
    >
      {(fluid || file) && (
        <div
          className={cn("w-full flex items-start justify-center", {
            [style.border]: border,
          })}
          style={{
            backgroundColor: background,
          }}
        >
          {fluid && (
            <Img
              ref={imageRef}
              fluid={{ ...fluid, sizes }}
              className={cn({
                "w-3/4": border,
                "w-full": !border,
              })}
            />
          )}
          {!fluid && file && (
            <Video
              src={file.url}
              className={cn("object-cover", {
                "w-3/4": border,
                "w-full": !border,
              })}
            />
          )}
        </div>
      )}
      {description && (
        <div className={cn("pt-2 text-center", style.description)}>
          <div className={"text-x10 desktop:text-x7"}>{description}</div>
        </div>
      )}
    </div>
  )
}

GridItem.propTypes = {
  description: PropTypes.string,
}
