import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import GridItem from "@/components/projectMediaGrid/gridItem"
import cn from "classnames"

const ProjectMediaGrid = React.memo(
  ({ rows }) => (
    <div className={"pt-20"}>
      {rows.map((row, i) => (
        <div
          key={i}
          className={cn(
            { "desktop:flex": row.length === 2 },
            `_length-${row.length}`
          )}
        >
          {row.map((item, j) => (
            <GridItem
              key={j}
              className={cn({
                "pb-6": true,
                "desktop:pr-3": row.length === 2 && j === 0,
                "desktop:pl-3": row.length === 2 && j === 1,
              })}
              {...item}
              sizes={
                row.length === 2 ? "(min-width: 768px) 50vw, 100vw" : undefined
              }
            />
          ))}
        </div>
      ))}
    </div>
  ),
  () => true
)

ProjectMediaGrid.propTypes = {
  rows: PropTypes.array,
}

export default ProjectMediaGrid

export const query = graphql`
  fragment ProjectMediaGridRow on ContentfulAsset {
    description
    fluid(maxWidth: 1200, quality: 70, toFormat: JPG) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }
    file {
      url
    }
  }

  fragment ProjectMediaGrid on ContentfulProjectMediaGrid {
    row1 {
      ...ProjectMediaGridRow
    }
    row2 {
      ...ProjectMediaGridRow
    }
    row3 {
      ...ProjectMediaGridRow
    }
    row4 {
      ...ProjectMediaGridRow
    }
    row5 {
      ...ProjectMediaGridRow
    }
    row6 {
      ...ProjectMediaGridRow
    }
    row7 {
      ...ProjectMediaGridRow
    }
    row8 {
      ...ProjectMediaGridRow
    }
    row9 {
      ...ProjectMediaGridRow
    }
    row10 {
      ...ProjectMediaGridRow
    }
    row11 {
      ...ProjectMediaGridRow
    }
    row12 {
      ...ProjectMediaGridRow
    }
    row13 {
      ...ProjectMediaGridRow
    }
    row14 {
      ...ProjectMediaGridRow
    }
    row15 {
      ...ProjectMediaGridRow
    }
    row16 {
      ...ProjectMediaGridRow
    }
    row17 {
      ...ProjectMediaGridRow
    }
    row18 {
      ...ProjectMediaGridRow
    }
    row19 {
      ...ProjectMediaGridRow
    }
    row20 {
      ...ProjectMediaGridRow
    }
    row21 {
      ...ProjectMediaGridRow
    }
    row22 {
      ...ProjectMediaGridRow
    }
    row23 {
      ...ProjectMediaGridRow
    }
    row24 {
      ...ProjectMediaGridRow
    }
    row25 {
      ...ProjectMediaGridRow
    }
    row26 {
      ...ProjectMediaGridRow
    }
    row27 {
      ...ProjectMediaGridRow
    }
    row28 {
      ...ProjectMediaGridRow
    }
    row29 {
      ...ProjectMediaGridRow
    }
    row30 {
      ...ProjectMediaGridRow
    }
  }
`
