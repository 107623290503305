import React from "react"
import { useIntersection } from "react-use"
import cn from "classnames"
import store from "@/util/locomotiveScroll/store"

const Video = ({ src, children, className, ...rest }) => {
  const [isLoaded, setLoaded] = React.useState(false)
  const rootRef = React.useRef(null)
  const videoRef = React.useRef(null)
  const rafRef = React.useRef(null)
  const timeoutRef = React.useRef(null)

  const intersection = useIntersection(videoRef, {
    root: null,
    rootMargin: "0px",
  })
  const handleVideoLoaded = React.useCallback(() => {
    setLoaded(true)
    store.get()?.update()
  }, [])

  React.useEffect(() => {
    if (intersection) {
      if (intersection.isIntersecting && isLoaded) {
        videoRef.current.play().catch(() => {})

        rafRef.current = window.requestAnimationFrame(() => {
          videoRef.current.style.opacity = 1

          timeoutRef.current = setTimeout(() => {
            rafRef.current = window.requestAnimationFrame(() => {
              rootRef.current.style.background = ""
            })
          }, 1300)
        })
      }
    }

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
      rafRef.current && cancelAnimationFrame(rafRef.current)
    }
  }, [intersection, intersection?.isIntersecting, isLoaded])

  React.useEffect(() => {
    const videoCurrent = videoRef.current

    if (videoCurrent) {
      videoCurrent.addEventListener("canplay", handleVideoLoaded)
    }

    return function cleanup() {
      videoCurrent.removeEventListener("canplay", handleVideoLoaded)
    }
  }, [videoRef])

  return (
    <div
      ref={rootRef}
      className={className}
      style={{
        backgroundColor: "#1a1a1a",
      }}
    >
      <video
        ref={videoRef}
        src={src}
        className="object-cover w-full transition-opacity duration-700 opacity-0"
        autoPlay
        playsInline
        muted
        preload={"auto"}
        loop
        style={{ transitionDelay: `500ms` }}
        {...rest}
      >
        {children}
      </video>
    </div>
  )
}

export default Video
