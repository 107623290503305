import React, { useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import Link from "@/components/ani-link"
import findIndex from "lodash/findIndex"
import { ActiveProject } from "@/state/containers"
import ProjectMediaGrid from "@/components/projectMediaGrid"
import RichText from "@/util/RichText"
import cn from "classnames"
import typograf from "@/util/typograf"
import * as style from "./project.modules.scss"

function objectValues(gridObj) {
  return Object.keys(gridObj).reduce((acc, currKey) => {
    if (gridObj[currKey]) {
      acc.push(gridObj[currKey])
    }
    return acc
  }, [])
}

function extractParams(text) {
  const [jsonMaybe] = /{.+}/.exec(text) || []
  let result = null

  try {
    result = JSON.parse(jsonMaybe)
  } catch (err) {
    console.error(
      "gridItem/extractParams: syntax error while parsing JSON description. You should check if the JSON format is correct"
    )
  }

  return result
}

export default function ProjectPage({ data, transitionStatus }) {
  const { allContentfulProjectsList, allContentfulProject } = data

  const currentProject = allContentfulProject?.edges?.[0]?.node
  const title = currentProject?.title?.title
  const description = currentProject?.longDescription?.raw

  const grid = useMemo(
    () =>
      currentProject.grid &&
      objectValues(currentProject.grid).map((row) =>
        row.map((item) => ({
          ...item,
          ...(extractParams(item.description) || {
            background: "#fff",
            border: true,
          }),
          description: item.description
            .replace(/{.+}/, "")
            .replace(/(\n|\r\n|\s){2,}/, ""),
        }))
      ),
    [currentProject.grid]
  )

  const projectsList = allContentfulProjectsList?.edges?.[0]?.node?.projects
  const next =
    projectsList[
      (findIndex(projectsList, (x) => x.slug === currentProject.slug) + 1) %
        projectsList.length
    ]

  const activeProject = ActiveProject.useContainer()

  useEffect(() => {
    activeProject.set({ title })
  }, [title])

  return (
    <div className={`relative pt-12 g-transition-status-${transitionStatus}`}>
      <div className={cn(style.contentWrapper, "px-3 desktop:px-7")}>
        {title && (
          <h1
            className={
              "text-center text-x3s desktop:text-x4s desktop:pt-10 desktop:w-4/6 mx-auto"
            }
          >
            {title}
          </h1>
        )}
        {description && (
          <div
            className={
              "text-center text-x6 desktop:text-x5 pt-10 desktop:w-4/6 mx-auto px-3 desktop:px-0"
            }
          >
            <RichText>{description}</RichText>
          </div>
        )}

        {grid && <ProjectMediaGrid rows={grid} />}
      </div>
      {next ? (
        <>
          <Link to={`/${next.slug}`} className={style.nextProjectPlaceholder} />
          <div
            className={cn(
              style.nextProjectLink,
              "fixed bottom-0 left-0 w-full flex flex-col justify-center items-center text-center px-3"
            )}
          >
            <div className={"text-x10 desktop:text-x6s pb-5"}>Next</div>
            {next.title && (
              <h1
                className={
                  "text-center text-x6s desktop:text-x3s desktop:w-4/6"
                }
              >
                {typograf(next.title.title)}
              </h1>
            )}
            {next.shortDescription && (
              <div
                className={
                  "text-center text-x6 desktop:text-x3 desktop:w-4/6 px-3 desktop:px-0 pt-2 desktop:pt-0"
                }
              >
                {typograf(next.shortDescription.shortDescription)}
              </div>
            )}
          </div>
        </>
      ) : null}
    </div>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    allContentfulProject(
      filter: { node_locale: { eq: "en-US" }, slug: { eq: $slug } }
    ) {
      edges {
        node {
          slug
          title {
            title
          }
          longDescription {
            raw
          }
          grid {
            ...ProjectMediaGrid
          }
        }
      }
    }
    allContentfulProjectsList(
      filter: {
        slug: { eq: "projects-all" }
        projects: { elemMatch: { node_locale: { eq: "en-US" } } }
      }
    ) {
      edges {
        node {
          projects {
            title {
              title
            }
            slug
            shortDescription {
              shortDescription
            }
          }
        }
      }
    }
  }
`
