import React, { useMemo } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export default function RichText({ children }) {
  const json = useMemo(() => {
    try {
      return JSON.parse(children)
    } catch (err) {
      return null
    }
  }, [children])

  return json !== null ? <>{documentToReactComponents(json)}</> : null
}
